import { Document } from "@contentful/rich-text-types";
import { Asset, ContentTypeLink, Entry, EntryFields, EntrySkeletonType } from "contentful";
import { PLUGIN } from "src/app/common/enums/plugins";
import { SLOT } from "src/app/common/enums/slot";
import { ICFPolicy } from "src/app/common/interfaces/app/policy";
import { Division } from "src/app/core/interfaces/division";
import { EntityConcession } from "./project-entity";

export enum ContentfulContentType {
  Infokachel = "infokachel",
  CPRegulatedModulesNews = "kpRegulatedNews",
  CPServicesModulesNews = "kpServicesNews",
  NBRegulatedModulesNews = "netzeBwRegulatedNews",
  NBServicesModulesNews = "netzeBwServicesNews",
  RegulatedModulesWebinars = "regulatedWebinars",
  ServicesModulesWebinars = "servicesWebinars",
  ToSAndPrivacyPolicy = "toSAndPrivacyPolicy",
}

export enum ContentfulActionType {
  ARCHIVE_PANEL = "ARCHIVE_PANEL",
  ENERGY_MONITOR_PANEL = "ENERGY_MONITOR_PANEL",
  INSTALLATION_OVERVIEW_PANEL = "INSTALLATION_OVERVIEW_PANEL",
  MAP_LAYERS_PANEL = "MAP_LAYERS_PANEL",
  OBJECT_SELECTION_PANEL = "OBJECT_SELECTION_PANEL",
  COCKPIT_PANEL = "COCKPIT_PANEL",
  BENCHMARKING_PANEL = "BENCHMARKING_PANEL",
  MEASURES_PANEL = "MEASURES_PANEL",
  MEASURES_LIST_PANEL = "MEASURES_LIST_PANEL",
  DATA_STORAGE_PANEL = "DATA_STORAGE_PANEL",
  CRISIS_MANAGEMENT_PANEL = "CRISIS_MANAGEMENT_PANEL",

  NOTIFICATION_SETTINGS_DIALOG = "NOTIFICATION_SETTINGS_DIALOG",
  WEBINAR_DIALOG_REGULATED = "WEBINAR_DIALOG_REGULATED",
  WEBINAR_DIALOG_SERVICES = "WEBINAR_DIALOG_SERVICES",
  EXTERNAL_URL = "EXTERNAL_URL",
  GLOSSARY_DIALOG = "GLOSSARY_DIALOG",
  CONTACT_DIALOG = "CONTACT_DIALOG",
  NEWS_OVERLAY = "NEWS_OVERLAY",

  CAMPUS_ONE_ORDER = "CAMPUS_ONE_ORDER"
}

export interface ContentfulActionField {
  label: string;
  action: ContentfulActionType;
  url?: string;
}

export interface ContentfulCommunalPlatformNewsEntry {
  headline: string;
  infotext: string;
  date: string; // date
  moduleName: SLOT;
  modulicon?: any;
  checkModule?: boolean;
  checkCritical?: boolean;
  overline?: string;
  division?: Division;
  actions?: Array<Entry<any>>;
}

export interface ContentfulNetzeBwNewsEntry extends EntityConcession {
  date: string; // date
  headline: string;
  teaser: string;
  teaserImage: any;
  video?: Asset;
  textArticle: string;
  checkAgs?: boolean;
  checkCritical?: boolean;
  agsList?: Array<string>;
  overline?: string;
  actions?: Array<Entry<any>>;
}

export interface ContentfulWebinarsEntry {
  headline: string;
  videoDescription: string;
  videoImage: any;
  video: any;
  date?: string; // date
  moduleName?: SLOT;
  checkModule?: boolean;
}

export interface ContentfulTileOnTileEntry {
  sign: string;
  teaser: string;
  moduleName: SLOT;
  actions: Array<Entry<any>>;
  checkModule?: boolean;
  division?: Division;
}

interface TypeMarketplaceBannerFields {
  moduleId: EntryFields.Symbol;
  linkUrl?: EntryFields.Symbol;
  teaserInfo: EntryFields.Symbol;
  logo: Asset;
  teaserImage?: Asset;
  price?: EntryFields.Number;
  teaserAddition?: EntryFields.Symbol;
  checkAgs?: EntryFields.Boolean;
  agsList?: EntryFields.Symbol[];
}

export type MarketplaceBannerEntry = Entry<EntrySkeletonType<TypeMarketplaceBannerFields>>;

export type CrmModuleName =
  | "streetLighting"
  | "shoppingCart"
  | "noysee"
  | "KEM"
  | "co2 balance"
  | "diginamic"
  | "quickCheckKWP";

export interface ContentfulSalesInfoEntry {
  moduleName: CrmModuleName;
  headline1: string;
  text1: Document;
  headline2: string;
  text2: Document;
  action?: { sys: ContentTypeLink };
  media?: Asset;
}

export const CrmModuleNamePluginMap: Record<CrmModuleName, PLUGIN> = {
  streetLighting: PLUGIN.STREET_LIGHTING,
  shoppingCart: PLUGIN.SHOPPING_CART,
  noysee: PLUGIN.NOYSEE,
  KEM: PLUGIN.KEM,
  "co2 balance": PLUGIN.CO2_BALANCE,
  diginamic: PLUGIN.DIGINAMIC,
  quickCheckKWP: PLUGIN.QUICK_CHECK
};

export type ContentfulEntry =
  | ContentfulCommunalPlatformNewsEntry
  | ContentfulNetzeBwNewsEntry
  | ContentfulWebinarsEntry
  | ContentfulTileOnTileEntry
  | ContentfulSalesInfoEntry;

type OrderFormFields = {
  moduleId: EntryFields.Symbol;
  headline: EntryFields.Symbol;
  price: EntryFields.Number;
  condition: EntryFields.Symbol;
  offer: EntryFields.Symbol;
  logo: Asset;
  productHeadline: EntryFields.Symbol;
  productDescription: EntryFields.Symbol;
  productMedia?: Array<Asset>;
  agb?: EntryFields.Symbol;
  privacyPolicy?: EntryFields.Symbol;
};
export type OrderFormEntry = Entry<EntrySkeletonType<OrderFormFields>>;
export type SalesInfoEntry = Entry<EntrySkeletonType<ContentfulSalesInfoEntry>>;

export interface ICFPolicySkeleton extends EntrySkeletonType {
  fields: ICFPolicy;
}